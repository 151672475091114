import React, { useState } from 'react'
import Navigation from '../components_main/Navigation'
import axios from 'axios'
import Helmet from 'react-helmet'
function AddUser() {
    const [userData, setUserData] = useState({
        name: "",
        role: "EDITOR",
        username: "",
        password: "",
        repeatedPassword: "",
    })
    const [userExists, setUserExists] = useState(false)
    const [passwordMatches, setPasswordMatches] = useState(true)

    const handleChange = (event) => {
        let tempData = {
            ...userData,
            [event.target.name]: event.target.value
        }
        if (tempData.repeatedPassword !== "" && tempData.password !== "" && tempData.repeatedPassword !== tempData.password)
            setPasswordMatches(false)
        else
            setPasswordMatches(true)

        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        })
    }
    const addUser = async () => {
        await axios.post(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/admin/user/`,
            {
                username: userData.username,
                password: userData.password,
                userRole: userData.role,
                name: userData.name,
            },
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            },
        ).catch((e) => {
            // //console.log(e)
            setUserExists(true)
        });
        window.location.assign(`${window.location.origin}/admin/uzytkownicy/dodaj-uzytkownika`)
    }
    return (
        <>
        <Helmet title='System Administracji Parafią | Dodaj Użytkownika '/>
            <Navigation />
            <div className="page-container">
                <div className='form' style={{ width: '50%', margin: 'auto' }}>
                    <h2>Dodawanie użytkownika</h2>
                    <label>Imię i nazwisko:</label>
                    <input type="text" id="name" name="name" placeholder='Imię i nazwisko' value={userData.name} onChange={handleChange} />
                    <label>Rola:</label>
                    <select type="text" id="role" name="userRole" value={userData.userRole} onChange={handleChange}>
                        <option value="EDITOR">Edytor</option>
                        <option value="ADMIN">Administrator</option>
                    </select>
                    <label>Nazwa użytkownika:</label>
                    <input type="text" id="username" name="username" placeholder='Nazwa użytkownika' value={userData.username} onChange={handleChange} />
                    <label>Hasło:</label>
                    <input type="password" id="password" name="password" placeholder='Hasło' value={userData.password} onChange={handleChange} />
                    <label>Powtórzone hasło:</label>
                    <input type="password" id="rep-password" name="repeatedPassword" placeholder='Powtórzone hasło' value={userData.repeatedPassword} onChange={handleChange} />
                    {
                        !passwordMatches ?
                            <label style={{ color: 'red', textAlign: 'center', fontWeight: 700 }}>Podane hasła różnią się</label>
                            : null
                    }
                    <button onClick={addUser}>DODAJ UŻYTKOWNIKA</button>
                    {
                        userExists ?
                            <label style={{ color: 'red', textAlign: 'center', fontWeight: 700 }}>Użytkownik o danej nazwie istnieje!</label>
                            : null
                    }
                </div>
            </div>

        </>
    )
}

export default AddUser
