import React, { useState, useEffect } from 'react'
import Navigation from './components_main/Navigation'
import { Link } from "react-router-dom"
import Helmet from 'react-helmet';
import axios from 'axios'

function Intentions() {
    var weekNumbers = Array.from({ length: 53 }, (_, i) => i + 1);
    const [selectedValue, setSelectedValue] = useState(1)
    const handleSelectedValue = (event) => {
        setSelectedValue(event.target.value)
    }
    const updateIntentions = () => {
        if (window.confirm(`Czy na pewno chcesz zaktualizować intencje?`)) {
            axios.post('https://api.netlify.com/build_hooks/64c38aeca5096d525af67bdc').then(() => {
                window.alert('Intecje zaktualizowano. Proszę poczekać kilka minut i odświeżyć główną stronę')
            })
        }
    }

    const [years, setYears] = useState({})
    const [loading, setLoaded] = useState(false)
    const getData = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/days/year`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            }
        );
        let temp_years = {
            'previous': [],
            'current': null,
            'nextYear': null,
            'next': [],
        }
        let current_year = new Date().getFullYear()
        temp_years['current'] = current_year

        let data_years = response.data

        for (let year of data_years) {
            if (year === current_year + 1)
                temp_years['nextYear'] = year
            else if (year > current_year + 1)
                temp_years['next'].push(year)
            else if (year < current_year)
                temp_years['previous'].push(year)
        }
        setYears(temp_years)
    }
    useEffect(() => {
        getData().then(() => {
            setLoaded(true)
        })
    }, [])
    if (!loading) {
        return (
            <>
                <Helmet title='Intencje | System Administracji Parafią' />
                <Navigation />
                <div className="page-container">
                    <div className="container-intentions">
                        <div className="calendar-config">
                            <h2>Kalenadarz Intencji ...</h2>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else
        return (<>
            <Helmet title='Intencje | System Administracji Parafią' />
            <Navigation />
            <div className="page-container">
                <div className="container-intentions">
                    <div className="calendar-config">
                        <h2>Kalenadarz Intencji</h2>
                        <label htmlFor='update'>Aktualizuj intencje: </label>
                        <button onClick={updateIntentions}>Aktualizuj</button>
                        <br />
                        <h4>Aktualny rok: </h4>
                        <Link to={`/admin/intencje/kalendarz-intencji/rok/${years.current}/1`}><button>Kalendarz {years.current}</button></Link>

                        {
                            years.nextYear ? <>
                                <h4>Następny rok: </h4>
                                <Link to={`/admin/intencje/kalendarz-intencji/rok/${years.nextYear}/1`}><button>Kalendarz {years.nextYear}</button></Link>
                            </> : null
                        }
                        {
                            years.previous.length > 0 ? <>
                                <h4>Archiwum: </h4>
                                {years.previous.map(year => {
                                    return (<>
                                        <Link to={`/admin/intencje/kalendarz-intencji/rok/${year}/1`}><button>Kalendarz {year}</button></Link>
                                        <br />
                                    </>
                                    )
                                })}
                            </> : null
                        }

                        {
                            years.next.length > 0 ? <>
                                <h4>Lata przyszłe: </h4>
                                {years.next.map(year => {
                                    return (<>
                                        <Link to={`/admin/intencje/kalendarz-intencji/rok/${year}/1`}><button>Kalendarz {year}</button></Link>
                                        <br />
                                    </>
                                    )
                                })}
                            </> : null
                        }
                    </div>
                </div>
            </div>

        </>
        )
}


export default Intentions