import { Outlet, Navigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
function PrivateRoutes() {
    const [logged, setLogged] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const login = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/test-login`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            },
        ).catch(e => {
            window.location.assign(`${window.location.origin}/`);
        })
        if (response.data !== undefined)
            setLogged(true)
    }
    useEffect(() => {
        login().then(() => {
            setLoaded(true)
        })
    }, []);
    if (loaded)
        return (
            logged ? <Outlet /> : <Navigate to="/" />
        )
    else return null
}

export default PrivateRoutes