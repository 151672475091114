import { React, useState } from 'react'
import banner from './images/banner.png'
import axios from 'axios'
import Helmet from 'react-helmet'
function MainPage() {
    const [authData, setAuthData] = useState({
        username: "",
        password: "",
    })

    const [unsuccessfulLogin, setUnsuccessfulLogin] = useState(false)

    const handleChange = (event) => {
        setAuthData({
            ...authData,
            [event.target.name]: event.target.value
        })
    }
    const login = async () => {
        setUnsuccessfulLogin(false)
        //console.log(`${process.env.REACT_APP_API_URL}:8080/api/v1/auth/login/${authData.username}`)
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/auth/login/${authData.username}`,
            {
                headers: { "Content-Type": "application/json" },
                auth: {
                    username: authData.username,
                    password: authData.password
                },
                withCredentials: true
            },
        ).catch(() => {
            setUnsuccessfulLogin(true)
        });
        window.sessionStorage.setItem("userID", response.data);
        window.location.assign(`${window.location.origin}/admin`);
    }
    const enterPress = (event) => {
        if (event.key === "Enter") {
            login()
        }
    }
    return (
        <div className="main-page-container">
            <Helmet title='Parafia Skoszewy | System Administracji Parafią'/>
            <div className="main-page-image">
                <img src={banner} alt="" />
                <div className="names">
                    <br />
                    <h1>System Administracji<br />Parafią</h1>
                    <br />
                    <br />
                    <h2>Parafia Stare Skoszewy</h2>
                    <br />
                </div>
            </div>
            <div className="log-form">
                <h2>Logowanie</h2>
                <div className='form'>
                    <label>Nazwa użytkownika:</label>
                    <input type="text" id="username" name="username" placeholder='Nazwa użytkownika' value={authData.username} onChange={handleChange} />
                    <label>Hasło:</label>
                    <input type="password" id="password" name="password" placeholder='Hasło' value={authData.password} onChange={handleChange} onKeyDown={enterPress}/>
                    <button onClick={login}>ZALOGUJ SIĘ</button>
                    {
                        unsuccessfulLogin ?
                            <label style={{ color: 'red', textAlign: 'center' }}>Podano nieprawidłowe<br />dane logowania!</label>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default MainPage