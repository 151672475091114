import React, { useState, useEffect } from 'react'
import Navigation from '../components_main/Navigation'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
function UserPage() {
    const [userData, setUserData] = useState({})
    const { id } = useParams();
    const [userExists, setUserExists] = useState(false)
    const [passwordMatches, setPasswordMatches] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const currentID = window.sessionStorage.getItem("userID")
    const handleChange = (event) => {
        let tempData = {
            ...userData,
            [event.target.name]: event.target.value
        }
        if (tempData.repeatedPassword !== "" && tempData.password !== "" && tempData.repeatedPassword !== tempData.password)
            setPasswordMatches(false)
        else
            setPasswordMatches(true)

        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        })
    }

    const getDefaultData = async () => {
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/user/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            let temp = {}
            for (const [key, value] of Object.entries(response.data)) {
                temp[key] = value
            }
            //console.log(temp)
            setUserData(temp)
            setLoaded(true)
        })

    }

    const addUser = async () => {
        await axios.put(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/admin/user/`,
            {
                id: parseInt(id),
                username: userData.username,
                password: userData.password,
                userRole: userData.userRole,
                name: userData.name,
            },
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            },
        ).then(() => window.location.assign(`${window.location.origin}/admin/uzytkownicy`))
        .catch((e) => {
            // //console.log(e)
            setUserExists(true)
        })
        
    }
    const deleteUser = async () => {
        await axios.delete(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/admin/user/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            },
        ).then(() => window.location.assign(`${window.location.origin}/admin/uzytkownicy`))
        .catch((e) => {
            // //console.log(e)
            setUserExists(true)
        })
    }
    useEffect(() => {
        getDefaultData()
    }, [])
    if (!loaded)
        return null
    else
        return (
            <>
                <Helmet title='Edycja Użytkownika | System Administracji Parafią' />
                <Navigation />
                <div className="page-container">
                    <div className='form' style={{ width: '50%', margin: 'auto' }}>
                        <h2>Edycja użytkownika</h2>
                        <label>Imię i nazwisko:</label>
                        <input type="text" id="name" name="name" placeholder='Imię i nazwisko' value={userData.name} onChange={handleChange} />
                        <label>Rola:</label>
                        <select type="text" id="role" name="userRole" value={userData.userRole} onChange={handleChange}>
                            <option value="EDITOR">Edytor</option>
                            <option value="ADMIN">Administrator</option>
                        </select>
                        <label>Nazwa użytkownika:</label>
                        <input type="text" id="username" name="username" placeholder='Nazwa użytkownika' value={userData.username} onChange={handleChange} />
                        <label>Hasło:</label>
                        <input type="password" id="password" name="password" placeholder='Hasło' value={userData.password} onChange={handleChange} />
                        <label>Powtórzone hasło:</label>
                        <input type="password" id="rep-password" name="repeatedPassword" placeholder='Powtórzone hasło' value={userData.repeatedPassword} onChange={handleChange} />
                        {
                            !passwordMatches ?
                                <label style={{ color: 'red', textAlign: 'center', fontWeight: 700 }}>Podane hasła różnią się</label>
                                : null
                        }
                        <button onClick={addUser}>EDYTUJ UŻYTKOWNIKA</button>
                        {
                            currentID !== id ?
                                <button onClick={deleteUser} style={{ backgroundColor: '#e74c3c' }}>USUŃ UŻYTKOWNIKA</button>
                                : null
                        }
                        {
                            userExists ?
                                <label style={{ color: 'red', textAlign: 'center', fontWeight: 700 }}>Użytkownik o danej nazwie istnieje!</label>
                                : null
                        }
                    </div>
                </div>

            </>
        )
}

export default UserPage
