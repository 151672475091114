import React, { useState, useEffect } from 'react'
import '../css/main.css'
import axios from "axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Day from './Day'
import { Link } from "react-router-dom"

const ISO8601_week_no = (dt) => {
    let tdt = new Date(dt.valueOf());
    let dayn = dt.getDay() % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    let firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

function Calendar(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [jumpDate, setJumpDate] = useState(`${props.year}-01-01`)

    const getData = async () => {
        let tempList = []
        let start = 1
        let end = 53
        if (props.weekNumber > 0 && props.weekNumber < 54) {
            start = props.weekNumber
            end = props.weekNumber
        }
        for (let i = start; i <= end; i++) {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/days/year/${props.year}/weekNumber/${i}`,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true
                }
            );

            let week = response.data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
            tempList.push(week)
            if (i === 1 || start === end) {
                setJumpDate(response.data[0].date)
            }
        }
        console.log(tempList)
        setData(tempList)
    }

    const handleScroll = e => {
        e.preventDefault()
        let up = document.querySelector('#up-button')
        if (window.scrollY > 100)
            up.style.opacity = 1
        else
            up.style.opacity = 0
    }

    useEffect(() => {
        getData().then(() => {
            setLoading(true)
            let params = new URL(document.location).searchParams;
            let jumpDate = params.get("jump-date")
            if (jumpDate !== null) {
                setJumpDate(jumpDate)
            }
            window.addEventListener('scroll', handleScroll)
        })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }

    }, [props])

    const formatDateWeek = (date) => {
        let tempDate = new Date(date)
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'long' })
    }
    const jumpToDate = () => {
        let date = document.querySelector('#jumpdatepicker').value
        let dateDiv = document.getElementById(date)
        if (dateDiv == null) {
            let jump_date = new Date(date)
            let weekNo = ISO8601_week_no(jump_date)
            window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/${weekNo}?jump-date=${date}`)
        }
        let top = dateDiv.offsetTop
        window.scrollTo(0, top - 90)
    }


    if (!loading) {
        return (
            <div className="container" style={{ flexDirection: 'column', minHeight: '60vh' }}>
                <h1 style={{ textAlign: 'center' }}>Ładowanie strony</h1>
            </div>
        )
    }
    else
        return (
            <>
                <div className="to-up">
                    <button id="up-button" onClick={(() => window.scrollTo(0, 0))}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </button>
                </div>
                <div className="container-calendar" style={{ flexDirection: 'column', minHeight: '60vh' }}>
                    <h1 style={{ textAlign: 'center' }}>Intencje na rok {props.year}</h1>
                    <div className="datepicker-container">
                        <input type="date"
                            id="jumpdatepicker"
                            min={`${props.year}-01-01`}
                            max={`${props.year}-12-31`}
                            value={jumpDate}
                            onChange={(event) => {
                                setJumpDate(event.target.value)
                            }}
                        >
                        </input>
                        <button onClick={jumpToDate}>
                            IDŹ DO DATY
                        </button>
                    </div>

                    <div className="query-container">
                        {/* {
                            props.weekNumber >= 1 && props.weekNumber <= 53 ?
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/-1`)} style={{ marginRight: 'auto' }}>
                                    Widok Roku
                                </button>
                                :
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/1`)} style={{ marginRight: 'auto' }}>
                                    Widok Tygodnia
                                </button>
                        } */}
                        {
                            props.weekNumber > 1 && props.weekNumber < 54 ?
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/${parseInt(props.weekNumber) - 1}`)}>
                                    <FontAwesomeIcon icon={faChevronLeft} /> {parseInt(props.weekNumber) - 1} Tydzień
                                </button>
                                : null
                        }
                        {
                            props.weekNumber >= 1 && props.weekNumber < 53 ?
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/${parseInt(props.weekNumber) + 1}`)}>
                                    {parseInt(props.weekNumber) + 1} Tydzień <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                : null
                        }
                    </div>
                    {
                        data.map((week, weekIndex) => {

                            return (
                                <div className="intentions-container">
                                    <h3>Od {formatDateWeek(week[0].date)} do {formatDateWeek(week[week.length - 1].date)}:</h3>
                                    {
                                        props.weekNumber < 1 || props.weekNumber > 53 ?
                                            <Link to={`/admin/intencje/kalendarz-intencji/rok/${props.year}/${weekIndex + 1}`} onClick={() => props.weekNumber++}>
                                                <button style={{ padding: '10px', backgroundColor: '#218C74', fontWeight: '700' }}>
                                                    IDŹ DO TYGODNIA
                                                </button>
                                            </Link> :
                                            null
                                    }
                                    {
                                        week.map(day => {
                                            return <Day id={day.id} />
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="query-container" style={{ marginBottom: 20 }}>
                        {/* {
                            props.weekNumber >= 1 && props.weekNumber <= 53 ?
                                    <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/-1`)} style={{marginRight: 'auto'}}>
                                        Widok Roku
                                    </button>
                                :
                                    <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/1`)} style={{marginRight: 'auto'}}>
                                        Widok Tygodnia
                                    </button>
                        } */}
                        {
                            props.weekNumber > 1 && props.weekNumber < 54 ?
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/${parseInt(props.weekNumber) - 1}`)}>
                                    <FontAwesomeIcon icon={faChevronLeft} /> {parseInt(props.weekNumber) - 1} Tydzień
                                </button>
                                : null
                        }
                        {
                            props.weekNumber >= 1 && props.weekNumber < 53 ?
                                <button onClick={() => window.location.assign(`${window.location.origin}/admin/intencje/kalendarz-intencji/rok/${props.year}/${parseInt(props.weekNumber) + 1}`)}>
                                    {parseInt(props.weekNumber) + 1} Tydzień <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                : null
                        }
                    </div>
                </div>
            </>

        )
}
export default Calendar