import React, { useEffect, useState } from 'react'
import Navigation from './components_main/Navigation'
import axios from "axios"
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
function Account() {
    const [data, setData] = useState({})

    const getData = async () => {
        let id = window.sessionStorage.getItem("userID")
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/user/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        setData(response.data)
    }

    useEffect(() => {
        getData()
    }
        , [])
    return (
        <>
            <Helmet title='Konto użytkownika | System Administracji Parafią' />
            <Navigation />
            <div className="page-container">
                <div className="user-info-container" style={{display:'flex', gap: 50}}>
                    <div style={{fontSize: 180}}><FontAwesomeIcon icon={faUser} /></div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        <div><strong>Użytkownik:</strong><br/>{data.username}</div>
                        <div><strong>Imię i nazwisko:</strong><br/>{data.name}</div>
                        <div><strong>Rola:</strong><br/>{data.userRole}</div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Account