import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faX } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
function Day(props) {
    const [day, setDay] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [changes, setChanges] = useState(false)

    const getData = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/days/sorted/${props.id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            }
        );
        setDay(response.data)
    }

    const addListeners = () => {
        // console.log('addListeners')
        const textareas = document.getElementsByClassName('mass-intention')
        const textareaList = [...textareas]
        textareaList.forEach(element => {
            let childList = [...element.children]
            element.style.height = `${childList[0].scrollHeight - 24}px`
            element.addEventListener('keyup', e => {
                element.style.height = '26px'
                const scrollHeight = e.target.scrollHeight
                element.style.height = `${scrollHeight - 24}px`
            })
        })
    }
    useEffect(() => {
        getData().then(() => {
            addListeners()
            setLoaded(true)
        })
    }, [])

    const saveData = async () => {
        for (let mass of day.masses) {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/mass/day/${props.id}`,
                mass,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true
                }
            )
            //console.log(response.data)
        }
    }
    const saveChanges = () => {
        saveData().then(() => {
            setChanges(false)
            //console.log('saved')
        })
    }

    const formatDate = (date, nameOfDay) => {
        let tempDate = new Date(date)
        return nameOfDay !== null ? nameOfDay + ', ' + tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric' })
            : tempDate.toLocaleDateString('pl-PL', { weekday: 'long', day: 'numeric', month: 'numeric' })
    }

    const formatTitle = (title, category) => {
        switch (category) {
            case 'NIEDZIELA':
            case 'UROCZYSTOSC_BEZ_MSZY_WIECZORNEJ':
            case 'UROCZYSTOSC_JAK_NIEDZIELA':
            case 'WIELKANOC':
            case 'BOZE_CIALO':
            case 'BOZE_NARODZENIE':
                return <span style={{ color: 'red' }}>{title}</span>
            default:
                return <span>{title}</span>
        }
    }


    const handleTimeChange = (event, massIndex) => {
        event.preventDefault()
        let tempData = JSON.parse(JSON.stringify(day))
        tempData.masses[massIndex].time = event.target.value + ':00'
        setDay(tempData)
        setChanges(true)
    }

    const handleIntentionChange = (event, massIndex) => {
        event.preventDefault()
        //console.log(event.target.value)
        let tempData = JSON.parse(JSON.stringify(day))
        tempData.masses[massIndex].intention = event.target.value
        setDay(tempData)
        setChanges(true)
    }
    const deleteMass = async (event, massIndex) => {
        event.preventDefault()
        if (!window.confirm(`Na pewno chcesz usunąć mszę w ${formatDate(day.date)} o ${day.masses[massIndex].time}`))
            return
        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/mass/${day.masses[massIndex].id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            }
        )
        let tempData = JSON.parse(JSON.stringify(day))
        tempData.masses.splice(massIndex, 1)
        setDay(tempData)
    }

    const addMass = async (event) => {
        event.preventDefault()
        let tempMass = {
            time: '00:00:00',
            intention: 'pusta intencja'
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/mass/day/${props.id}`,
            tempMass,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            }
        )
        //console.log(response.data)
        let tempData = JSON.parse(JSON.stringify(day))
        tempData.masses.push(response.data)
        setDay(tempData)
    }
    if (!loaded) return null
    else
        return (
            <div style={{ background: 'transparent', margin: '10px 0' }} id={`${day.date}`}>
                <strong style={{ textTransform: 'capitalize' }}>{formatTitle(formatDate(day.date, day.nameOfDay) + ',', day.category)}</strong>
                {
                    day.masses.map((mass, massIndex) => {
                        return (

                            <div className="mass">
                                <div className="mass-time">g.
                                    <input type='time' value={mass.time} onChange={(e) => handleTimeChange(e, massIndex)}></input>
                                </div>
                                <div className="mass-intention">
                                    <textarea className="intentions-textarea" rows='1' value={mass.intention} onChange={(e) => handleIntentionChange(e, massIndex)} />
                                </div>
                                <button style={{ margin: '0 10px', backgroundColor: '#e74c3c' }} onClick={(e) => deleteMass(e, massIndex)}>
                                    <FontAwesomeIcon icon={faX} />
                                </button>

                            </div>
                        )
                    })
                }
                <button style={{ margin: '10px 10px 0 0', backgroundColor: '#2980b9', padding: 10 }} onClick={(e) => addMass(e)}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span> DODAJ MSZĘ ŚW.</span>
                </button>
                {
                    changes ?
                        <button style={{ margin: '10px 10px 0 0', backgroundColor: '#218C74', padding: 10 }} onClick={saveChanges}>
                            ZAPISZ ZMIANY
                        </button>
                        : null
                }
            </div>
        )
}

export default Day