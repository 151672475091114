import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainPage from './MainPage'
import AdminPage from './AdminPage'
import Cemetery from './Cemetery'
import Intentions from './Intentions'
import IntentionsCalendar from './IntentionsCalendar'
import Grave from './components_admin/Grave'
import Users from './Users'
import AddUser from './components_admin/AddUser'
import Account from './Account'
import PrivateRoutes from './utils/PrivateRoutes'
import UserPage from './components_admin/UserPage'
import AllGraves from './components_admin/AllGraves'
import Szukaj from './szukaj'
import SectionGraves from './components_admin/SectionGraves'
import './css/main.css'
function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route element={<PrivateRoutes />}>
                    <Route exact path='/admin' element={<AdminPage />} />
                    <Route exact path='/admin/konto' element={<Account />} />
                    <Route exact path='/admin/cmentarz' element={<Cemetery />} />
                    <Route path='/admin/cmentarz/szukaj/*' element={<Szukaj />} />
                    <Route exact path='/admin/cmentarz/grob/:id' element={<Grave />} />
                    <Route exact path='/admin/cmentarz/groby/:id' element={<AllGraves />} />
                    <Route exact path='/admin/cmentarz/kwatera/:nr/strona/:id' element={<SectionGraves />} />
                    <Route exact path='/admin/uzytkownicy' element={<Users />} />
                    <Route exact path='/admin/uzytkownicy/:id' element={<UserPage />} />
                    <Route exact path='/admin/uzytkownicy/dodaj-uzytkownika' element={<AddUser />} />
                    <Route exact path='/admin/intencje' element={<Intentions />} />
                    {/* <Route exact path='/admin/intencje/kalendarz-intencji/:weekNumber' element={<IntentionsCalendar />} /> */}
                    <Route exact path='/admin/intencje/kalendarz-intencji/rok/:year/:weekNumber' element={<IntentionsCalendar />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
