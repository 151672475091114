import React, { useState, useEffect } from 'react'
import { NavLink, Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserPlus, faCalendar, faCross, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"

function Navigation() {
    const [handledModules, setHandledModules] = useState({})
    const [loaded, setLoaded] = useState(false)
    const getHandledModules = async () => {
        let modules = [{
            name: 'Użytkownicy',
            slug: '/admin/uzytkownicy',
            icon: faUserPlus
        },
        {
            name: 'Intencje',
            slug: '/admin/intencje',
            icon: faCalendar
        },
        {
            name: 'Cmentarz',
            slug: '/admin/cmentarz',
            icon: faCross
        }]
        let id = window.sessionStorage.getItem("userID")
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/user/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            if (response.data.userRole === "EDITOR")
                modules.splice(0, 1)
            setHandledModules(modules)
            setLoaded(true)
        }).catch(err => {
            window.location.pathname = '/'
        })
    }
    const logout = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/auth/logout`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            },
        );
        //console.log(response.data)
        window.location.assign(`${window.location.origin}/`)
    }
    let activeStyle = {
        color: '#218C74',
        borderLeft: '10px solid #218C74',
        backgroundColor: '#3b424b',
        textShadow: 'none',
        boxSizing: 'border-box'
    }
    useEffect(() => {
        getHandledModules()
    }, [])
    if (!loaded)
        return null
    else
        return (
            <>
                <nav className='main'>
                    <h2>Parafia Skoszewy</h2><h2 style={{ fontWeight: 700, position: 'absolute' }}><Link to='/admin'>System Administracji Parafią</Link></h2>
                </nav>
                <nav className='side'>
                    <ul>
                        <NavLink to='/admin/konto' style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }>
                            <li id='konto'><FontAwesomeIcon icon={faUser} style={{ width: 50 }} /><br />Konto</li>
                        </NavLink>
                        {
                            handledModules.map(mod => {
                                return (
                                    <NavLink to={mod.slug} style={({ isActive }) =>
                                        isActive ? activeStyle : undefined
                                    }>
                                        <li id={mod.slug.split('/')[2]}><FontAwesomeIcon icon={mod.icon} style={{ width: 50 }} /><br />{mod.name}</li>
                                    </NavLink>
                                )
                            })
                        }
                        <li onClick={logout} style={{ cursor: 'pointer', marginTop: 'auto', borderTop: '1px solid rgb(0 0 0 / 20%)', padding: '20px 0' }}>
                            <FontAwesomeIcon icon={faRightFromBracket} /><br />Wyloguj się
                        </li>
                    </ul>
                </nav>
            </>
        )
}

export default Navigation