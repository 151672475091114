import React from 'react'
import Navigation from './components_main/Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SearchBar from './components_admin/searchBar'
import axios from 'axios'
import Helmet from 'react-helmet'

function Cemetery() {
    const addGrave = async () => {
        let grave = {
            additionalInfo: null,
            dateOfGravePayment: null,
            dateOfPayment: null,
            graveType: null,
            images: [],
            latitude: 51.853719,
            longitude: 19.636496,
            people: [],
            position: "",
            responsiblePerson: null,
            row: "",
            sectionName: "I",
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/grave/`,
            grave,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then(response => {
            window.location.pathname = `/admin/cmentarz/grob/${response.data.id}`
        })
    }
    const updateGraves = () => {
        if (window.confirm(`Czy na pewno chcesz zaktualizować groby?`)) {
            axios.post('https://api.netlify.com/build_hooks/64c38aeca5096d525af67bdc').then(() => {
                window.alert('Groby zaktualizowano. Proszę poczekać kilka minut i odświeżyć główną stronę')
            })
        }
    }
    return (
        <>
            <Helmet title='Cmentarz | System Administracji Parafią' />
            <Navigation />
            <div className="page-container">
                <div className="container-cemetery">
                    <SearchBar />
                    <div className='add-grave'>
                        <h2>Zarządzanie grobami: </h2>
                        <label>Dodaj grób: </label>
                        <button onClick={addGrave}><FontAwesomeIcon icon={faPlus} /><span> DODAJ</span></button>
                        <br />
                        <label>Aktualizuj groby: </label>
                        <button onClick={updateGraves}>Aktualizuj</button>
                        <br />
                        <label>Wszystkie groby: </label>
                        <Link to='/admin/cmentarz/groby/1' style={{ marginLeft: 10 }}><button>PRZEJDŹ</button></Link>
                        <br />
                        <label>Poszczególne kwatery: </label><br />
                        <Link to='/admin/cmentarz/kwatera/1/strona/1' style={{ marginLeft: 10 }}><button>KWATERA I</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/2/strona/1' style={{ marginLeft: 10 }}><button>KWATERA II</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/3/strona/1' style={{ marginLeft: 10 }}><button>KWATERA III</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/4/strona/1' style={{ marginLeft: 10 }}><button>KWATERA IV</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/5/strona/1' style={{ marginLeft: 10 }}><button>KWATERA V</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/6/strona/1' style={{ marginLeft: 10 }}><button>KWATERA V/DZ</button></Link><br />
                        <Link to='/admin/cmentarz/kwatera/7/strona/1' style={{ marginLeft: 10 }}><button>KWATERA VI</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cemetery