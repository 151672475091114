import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchBar = () => {
    const [person, setPerson] = useState({
        firstName: null,
        lastName: null,
        deathDate: null,
        birthDate: null,
    })

    const handleChangePerson = (e) => {
        setPerson({
            ...person,
            [e.target.name]: e.target.value
        })
    }
    const seek = () => {
        //console.log(person)

        const url = new URL(window.location);
        url.pathname = '/admin/cmentarz/szukaj/'
        for (let [key, value] of Object.entries(person)) {
            if (value === "")
                value = null
            url.searchParams.set(key, value)
        }
        //console.log(url)
        window.location = url
    }
    return (
        <div className='search-bar'>
            <h2>Wyszukaj osobę  </h2>
            <div className='form-search-element'>
                <label>Nazwisko: </label>
                <input type="text" name="lastName" placeholder='Nazwisko' value={person.lastName} onChange={handleChangePerson} />
            </div>
            <div className='form-search-element'>
                <label>Imię: </label>
                <input type="text" name="firstName" placeholder='Imię' value={person.firstName} onChange={handleChangePerson} />
            </div>
            <div className='form-search-element'>
                <label>Rok śmierci: </label>
                <input type="number" name="deathDate" min="1900" max="2099" step="1" value={person.deathDate} onChange={handleChangePerson} placeholder='2022'/>
            </div>
            <div className='form-search-element'>
                <label>Rok urodzenia: </label>
                <input type="number" name="birthDate" min="1900" max="2099" step="1" value={person.birthDate} onChange={handleChangePerson} placeholder='1900'/>
            </div>
            <p>Nie trzeba wypełniać wszystkich pól, wypełnij tylko te, których szukasz.</p>
            <button onClick={seek}>
               <FontAwesomeIcon icon={faSearch} />  SZUKAJ
            </button>
        </div>
    );
}

export default SearchBar;
