import React, { useState, useEffect } from 'react';
import SearchBar from './components_admin/searchBar';
import axios from 'axios'
import tempGravePhoto from './images/grave-without-image.svg'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross } from '@fortawesome/free-solid-svg-icons'
import Navigation from './components_main/Navigation';
import Helmet from 'react-helmet';
const Szukaj = () => {
    const [graves, setGraves] = useState([])
    const [loaded, setLoaded] = useState(false)
    const formatDate = (date) => {
        let tempDate = new Date(date)
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }
    const getData = async () => {
        const params = new URLSearchParams(window.location.search)
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/grave/query/fn/${params.get("firstName")}/ln/${params.get("lastName")}/dd/${params.get("deathDate")}/bd/${params.get("birthDate")}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setGraves(response.data)
            //console.log(response.data)
            setLoaded(true)
        })
    }
    useEffect(() => {
        getData()
    }, [])

    if (!loaded)
        return null
    else
        return (
            <>
                <Helmet title='Wyszukiwarka | System Administracji Parafią' />
                <Navigation />
                <div className="page-container">
                    <div className="container-search-bar">
                        <div className="search-results">
                            <h2>Rezulat wyszukiwania:</h2>
                            {
                                graves.length === 0 ?
                                    <div style={{ marginTop: '1em' }}>Brak wyników</div>
                                    : null
                            }
                            {
                                graves.map(grave => {
                                    return (
                                        <Link to={`/admin/cmentarz/grob/${grave.id}`}>
                                            <div className="grave">
                                                {
                                                    grave.images.length === 0 ?
                                                        <img src={tempGravePhoto} alt='' width='35%' height='auto' style={{aspectRatio: '1/1'}}/> :
                                                        <img src={`${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[0].name}`} alt='' width='35%' height='480px' />
                                                }
                                                <div className='people'>
                                                    <h3>
                                                        Kwatera: <strong>{grave.sectionName}</strong><br />
                                                        Rząd: <strong>{grave.row}</strong> Pozycja: <strong>{grave.position}</strong>
                                                        <br />
                                                        <br />
                                                        <span style={{ fontSize: 80 }}>
                                                            <strong><FontAwesomeIcon icon={faCross} /></strong>
                                                        </span>
                                                        <br /><br />
                                                        <strong style={{ fontSize: 32 }}>Ś.P.</strong>
                                                    </h3>
                                                    {
                                                        grave.people.map(person => {
                                                            return (
                                                                <div className="person">
                                                                    <strong>{person.firstName} {person.lastName}</strong><br />
                                                                    zm. {formatDate(person.deathDate)} r.
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div style={{ flex: 2 }}>
                            <SearchBar />
                        </div>
                    </div>
                </div>
            </>
        );
}

export default Szukaj;
