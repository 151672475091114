import React, { useState, useEffect } from 'react'
import Navigation from './components_main/Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Helmet from 'react-helmet'
function Users() {
    const [usersData, setUsersData] = useState([])
    const [loaded, setLoaded] = useState(false)

    const getUsersData = async () => {
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/admin/user`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setUsersData(response.data)
            setLoaded(true)
        })
    }
    useEffect(() => {
        getUsersData()
    }, [])
    if (!loaded)
        return null
    else
        return (
            <>
                <Helmet title='Użytkownicy | System Administracji Parafią' />
                <Navigation />
                <div className="page-container">
                    <div className="container-cemetery">

                        <div className='add-grave'>
                            <h2>Zarządzanie użytkownikami: </h2>
                            <label>Dodaj użytkownika: </label>
                            <Link to='/admin/uzytkownicy/dodaj-uzytkownika' style={{ marginLeft: 10 }}><button><FontAwesomeIcon icon={faPlus} /><span> DODAJ</span></button></Link>
                            <br />
                            <br />
                            <h3>Lista użytkowników: </h3>
                            {
                                usersData.map(user => {
                                    return (
                                        <Link to={`/admin/uzytkownicy/${user.id}`} style={{ marginLeft: 10 }}>
                                            <div style={{ width: 300, backgroundColor: 'white', padding: 10 }}>
                                                <FontAwesomeIcon icon={faUser} /><span style={{ marginLeft: 10 }}> {user.username}</span>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </>
        )
}

export default Users