import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navigation from './components_main/Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faCalendar, faCross } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Helmet from 'react-helmet'
function AdminPage() {
    const [handledModules, setHandledModules] = useState({})
    const [loaded, setLoaded] = useState(false)
    const getHandledModules = async () => {
        let modules = [{
            name: 'Użytkownicy',
            slug: '/admin/uzytkownicy',
            icon: faUserPlus
        },
        {
            name: 'Intencje',
            slug: '/admin/intencje',
            icon: faCalendar
        },
        {
            name: 'Cmentarz',
            slug: '/admin/cmentarz',
            icon: faCross
        }]
        let id = window.sessionStorage.getItem("userID")
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/user/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            // //console.log(response.data)
            if (response.data.userRole === "EDITOR")
                modules.splice(0, 1)
            setHandledModules(modules)
            setLoaded(true)
        })
    }

    useEffect(() => {
        getHandledModules()
    }, [])
    if (!loaded)
        return null
    else
        return (<>
            <Helmet title='Strona administratora | System Administracji Parafią' />
            <Navigation />
            <div className="page-container">
                <div className="admin-info">
                    <h2>Witaj Użytkowniku!</h2>
                    <h3>Obsługiwane moduły:</h3>
                </div>
                <div className="handled-modules">
                    {
                        handledModules.map(mod => {
                            return (
                                <Link to={mod.slug}><div className="module"><FontAwesomeIcon icon={mod.icon} style={{ width: 50, marginBottom: 20 }} />{mod.name}</div></Link>
                            )
                        })
                    }
                </div>
            </div>
        </>
        )
}

export default AdminPage