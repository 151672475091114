import React from 'react'
import Navigation from './components_main/Navigation'
import Calendar from './components_admin/Calendar'
import { useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
function IntentionsCalendar() {
    const { year, weekNumber } = useParams();
    return (
        <>
            <Helmet title='Kalendarz Intencji | System Administracji Parafią'/>
            <Navigation />

            <div className="page-container">
                <Calendar year={year} weekNumber={weekNumber} />
            </div>
        </>
    )
}

export default IntentionsCalendar