import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import Navigation from '../components_main/Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Popup, ImageOverlay } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import EXIF from 'exif-js'
import Helmet from 'react-helmet'
import mainMap from '../images/main_map_rott.png'

function Grave() {
    var emptyPerson = {
        firstName: "",
        lastName: "",
        deathDate: null,
        birthDate: null,
        age: null,
    }
    // var emptyImage = {
    //     name: null,
    //     type: null,
    //     image: []
    // }
    // var emptyGrave = {
    //     latitude: 51.123,
    //     longitude: 23.34,
    //     graveSize: 'SINGLE',
    //     dateOfPayment: null,
    //     row: null,
    //     position: null,
    //     section: null,
    //     people: [emptyPerson],
    //     images: [emptyImage]
    // }
    var { id } = useParams();
    const [grave, setGrave] = useState({})
    const [photos, setPhotos] = useState([{ file: null, url: null, toUpdate: false, id: null }, { file: null, url: null, toUpdate: false, id: null }]);
    const [loaded, setLoaded] = useState(false)



    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState(null)
    const markerRef = useRef(null)

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])



    const handleChangeGrave = (e) => {
        e.preventDefault()
        let tempGrave = JSON.parse(JSON.stringify(grave))
        tempGrave[e.target.name] = e.target.value
        setGrave(tempGrave)
    }
    const handleChangePerson = (e, personIndex) => {
        e.preventDefault()
        let tempGrave = JSON.parse(JSON.stringify(grave))
        tempGrave.people[personIndex][e.target.name] = e.target.value
        //console.log(tempGrave)
        setGrave(tempGrave)
    }
    const addPerson = async () => {
        let tempGrave = JSON.parse(JSON.stringify(grave))
        let resonse = await axios.post(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/person/grave/${id}`,
            emptyPerson,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )
        tempGrave.people.push(resonse.data)
        setGrave(tempGrave)
    }
    const deletePerson = async (personIndex) => {
        if (window.confirm(`Czy na pewno chcesz usunąć ${grave.people[personIndex].firstName} ${grave.people[personIndex].lastName}?`)) {
            let tempGrave = JSON.parse(JSON.stringify(grave))
            tempGrave.people.splice(personIndex, 1)
            await axios.delete(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/person/${grave.people[personIndex].id}`,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            setGrave(tempGrave)
        }
    }

    const deleteGrave = async () => {
        if (window.confirm(`Czy na pewno chcesz usunąć ten grób?`)) {
            for (let person of grave.people) {
                await axios.delete(
                    `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/person/${person.id}`,
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
            }
            await axios.delete(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/grave/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            window.location.assign(`${window.location.origin}/admin/cmentarz/groby/1`);
        }
    }

    const getData = async () => {
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/grave/${id}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setGrave(response.data)
            for (let [index, image] of response.data.images.entries()) {
                photos[index].file = null
                photos[index].url = `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/image/filename/server_${image.name}`
                photos[index].id = image.id
            }
            setPosition([response.data.latitude, response.data.longitude])
            // console.log(response.data)
            setLoaded(true)
        })
    }


    useEffect(() => {
        getData()
    }, [])

    const handleImageChange = (e) => {
        let file = e.target.files[0];
        if (e.target.name === 'firstPhoto' && file && file.name) {
            EXIF.getData(file, function () {
                //console.log(EXIF.getTag(this, 'GPSLatitude'))
                //console.log(EXIF.getTag(this, 'GPSLongitude'))
                let longitude = EXIF.getTag(this, 'GPSLongitude')
                let latitude = EXIF.getTag(this, 'GPSLatitude')
                let longitudeValue = longitude[0] + longitude[1] / 60 + longitude[2] / 3600
                let latitudeValue = latitude[0] + latitude[1] / 60 + latitude[2] / 3600
                setGrave({
                    ...grave,
                    'longitude': longitudeValue,
                    'latitude': latitudeValue
                })
                setPosition([latitudeValue, longitudeValue])
            })
        }
        if (e.target.name === 'firstPhoto') {
            let tempPhoto = { file: null, url: null, toUpdate: false, id: null }
            tempPhoto.file = e.target.files[0]
            tempPhoto.url = URL.createObjectURL(e.target.files[0])
            tempPhoto.toUpdate = true
            //console.log(tempPhoto)
            setPhotos([tempPhoto, photos[1]])
        }
        else if (e.target.name === 'secondPhoto') {
            let tempPhoto = { file: null, url: null, toUpdate: false, id: null }
            tempPhoto.file = e.target.files[0]
            tempPhoto.url = URL.createObjectURL(e.target.files[0])
            tempPhoto.toUpdate = true
            //console.log(tempPhoto)
            setPhotos([photos[0], tempPhoto])
        }
    }
    const submit = async () => {
        const marker = markerRef.current
        let longitudeValue = 0
        let latitudeValue = 0
        if (marker != null) {
            let pos = marker.getLatLng()
            longitudeValue = pos.lng
            latitudeValue = pos.lat
        }
        let tempGrave = {
            ...grave,
            'longitude': longitudeValue,
            'latitude': latitudeValue
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/grave/`,
            tempGrave,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )
        for (let person of grave.people) {
            await axios.post(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/person/grave/${id}`,
                person,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
        }
        if (photos[0].toUpdate) {
            let formImage = new FormData()
            formImage.append('image', photos[0].file)
            //console.log(formImage)
            await axios.post(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/image/grave/${id}`,
                formImage,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                }
            )
        }
        if (photos[1].toUpdate) {
            let formImage = new FormData()
            formImage.append('image', photos[1].file)
            //console.log(formImage)
            await axios.post(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/image/grave/${id}`,
                formImage,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                }
            )
        }
        window.location.pathname = `admin/cmentarz/grob/${id}`
    }

    const deletePhoto = async (e) => {
        //console.log(e.target.name)
        let index = e.target.name === "firstPhoto" ? 0 : 1
        if (window.confirm('Potwierdź usunięcie zdjęcia')) {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}:8080/api/v1/editor/image/${photos[index].id}`,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                }
            )
        }
        window.location.pathname = `admin/cmentarz/grob/${id}`
    }
    if (!loaded)
        return null
    else
        return (
            <>
                <Helmet title={`Kwatera ${grave.sectionName} Grób: ${grave.row} - ${grave.position} | System Administracji Parafią`} />
                <Navigation />
                <div className="page-container">
                    <div className="grave-form">
                        <h3 style={{ marginBottom: 'auto' }}>Zdjęcia:</h3>
                        <button style={{ width: '40px', backgroundColor: '#e74c3c', position: 'absolute', top: 10, right: 40 }} onClick={deleteGrave}>
                            <FontAwesomeIcon icon={faX} />
                        </button>
                        <div className='grave-images'>
                            <div className="grave-image">
                                <img src={photos[0].url} alt="" height={450} />
                                {
                                    photos[0].url !== null && photos[0].toUpdate === false ?
                                        <button name="firstPhoto" style={{ backgroundColor: '#e74c3c' }} onClick={deletePhoto}>
                                            <FontAwesomeIcon icon={faX} /> USUŃ
                                        </button> :
                                        <input className='input-photo' type="file" name="firstPhoto" onChange={handleImageChange} width={200} />

                                }
                            </div>
                            <div className="grave-image">
                                <img src={photos[1].url} alt="" height={450} />
                                {
                                    photos[1].url !== null && photos[1].toUpdate === false ?
                                        <button name="secondPhoto" style={{ backgroundColor: '#e74c3c' }} onClick={deletePhoto}>
                                            <FontAwesomeIcon icon={faX} /> USUŃ
                                        </button> :
                                        <input className='input-photo' type="file" name="secondPhoto" onChange={handleImageChange} width={200} />
                                }
                            </div>
                        </div>
                        <h3>Osoby:</h3>
                        <div className="people">
                            {
                                grave.people.map((person, personIndex) => {
                                    return (
                                        <div className="single-person">
                                            <div>
                                                <label for="fname">Imię: </label>
                                                <input type="text" name="firstName" placeholder='Imię' value={person.firstName} onChange={(e) => handleChangePerson(e, personIndex)} />
                                            </div>
                                            <div>
                                                <label for="lname">Nazwisko: </label>
                                                <input type="text" name="lastName" placeholder='Nazwisko' value={person.lastName} onChange={(e) => handleChangePerson(e, personIndex)} />
                                            </div>

                                            <div>
                                                <label for="death-date">Data śmierci: </label>
                                                <input type="date" name="deathDate" value={person.deathDate} onChange={(e) => handleChangePerson(e, personIndex)} />
                                            </div>
                                            <div>
                                                <label for="bddate">Data urodzenia: </label>
                                                <input type="date" name="birthDate" value={person.birthDate} onChange={(e) => handleChangePerson(e, personIndex)} />
                                            </div>
                                            <div>
                                                <label for="age">Wiek: </label>
                                                <input type="text" name="age" style={{ width: 40 }} value={person.age} onChange={(e) => handleChangePerson(e, personIndex)} />
                                                <button style={{ margin: '0 0 0 30px', backgroundColor: '#e74c3c' }} onClick={() => deletePerson(personIndex)}>
                                                    <FontAwesomeIcon icon={faX} />
                                                </button>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                            <button onClick={addPerson}>
                                DODAJ OSOBĘ
                            </button>
                        </div>
                        <h3 style={{ marginBottom: 'auto' }}>Informacje:</h3>
                        <div className="grave-info">
                            <div className="column">
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Sekcja: </label>
                                    <input name="sectionName" type="text" value={grave.sectionName} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Rząd: </label>
                                    <input name="row" type="text" value={grave.row} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Pozycja: </label>
                                    <input name="position" type="text" value={grave.position} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Rodzaj: </label>
                                    <input name="graveType" type="text" value={grave.graveType} onChange={handleChangeGrave} />
                                </div>
                            </div>

                            <div className="column">
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Data opłacenia grobu: </label>
                                    <input name="dateOfPayment" type="date" value={grave.dateOfPayment} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Data opłacenia nagrobka: </label>
                                    <input name="dateOfGravePayment" type="date" value={grave.dateOfGravePayment} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Dysponent grobu: </label>
                                    <input name="responsiblePerson" type="text" value={grave.responsiblePerson} onChange={handleChangeGrave} />
                                </div>
                                <div style={{ margin: 'auto 0' }} className='form-field'>
                                    <label>Dodatkowe informacje: </label>
                                    <textarea name='additionalInfo' value={grave.additionalInfo} onChange={handleChangeGrave} />
                                </div>
                            </div>
                        </div>
                        <h3>Mapa:<button style={{marginLeft: 40}} onClick={toggleDraggable}>{!draggable ? <>EDYTUJ POZYCJĘ</>:<>SKOŃCZ EDYCJĘ</>}</button></h3>
                        <div className="map">
                            <MapContainer center={[grave.latitude, grave.longitude]} zoom={21} style={{ height: "100%", width: "100%" }} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    maxZoom={25}
                                />
                                <ImageOverlay
                                    url={mainMap}
                                    bounds={[[51.852450, 19.635040], [51.854930, 19.637900]]}
                                    opacity={0.8}
                                    zIndex={10}
                                />
                                <Marker
                                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                                    draggable={draggable}
                                    eventHandlers={eventHandlers}
                                    position={position}
                                    ref={markerRef}>
                                </Marker>
                            </MapContainer>
                        </div>
                        <button onClick={submit}>ZAPISZ ZMIANY</button>
                    </div>
                </div>

            </>
        )
}

export default Grave
