import React, { useState, useEffect, useReducer } from 'react'
import Navigation from '../components_main/Navigation'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { faCross, faChevronRight, faChevronLeft, faX, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
function AllGraves() {
    var { id } = useParams();
    const [graves, setGraves] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [maxSites, setMaxSites] = useState(1)
    const forceUpdate = useReducer(() => ({}), {})
    const formatDate = (date) => {
        let tempDate = new Date(date)
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }
    const getData = async () => {
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/grave/no/${id}/chunk/48`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setGraves(response.data)
            //console.log(response.data)
        })
        await axios.get(
            `${process.env.REACT_APP_API_URL}:8080/api/v1/noauth/grave/no/chunk/48`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            setMaxSites(response.data)
            setLoaded(true)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <Helmet title={`Wszystkie groby: Strona ${id} | System Administracji Parafią `} />
            <Navigation />
            <div className="page-container">
                <div className="container-cemetery">
                    <div className='all-graves'>
                        <div style={{ display: 'flex', gap: 20, padding: '20px 0', alignContent: 'center', flex: '100%' }}>
                            {
                                id > 1 ?
                                    <Link to={`/admin/cmentarz/groby/${parseInt(id) - 1}`} onClick={forceUpdate}>
                                        <button>
                                            <FontAwesomeIcon icon={faChevronLeft} /> Strona {parseInt(id) - 1}
                                        </button>
                                    </Link>
                                    : null
                            }
                            {
                                id >= 1 && id < maxSites ?
                                    <Link to={`/admin/cmentarz/groby/${parseInt(id) + 1}`} onClick={forceUpdate}>
                                        <button>
                                            Strona {parseInt(id) + 1} <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </Link>
                                    : null
                            }

                        </div>
                        {
                            loaded ? <>
                                {
                                    graves.map(grave => {
                                        return (
                                            <div className="grave">
                                                <Link to={`/admin/cmentarz/grob/${grave.id}`}>
                                                    <span style={{ fontSize: 24, display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                                                        <strong>Kwatera {grave.sectionName}</strong>
                                                    </span>
                                                    <div className='description'>
                                                        Rząd: <strong>{grave.row}</strong> Pozycja: <strong>{grave.position}</strong>
                                                    </div>
                                                    <span style={{ fontSize: 40, display: 'flex', justifyContent: 'center' }}>
                                                        <strong><FontAwesomeIcon icon={faCross} /></strong>
                                                    </span>
                                                    <div className='people'>
                                                        {
                                                            grave.people.map(person => {
                                                                return (
                                                                    <div className="person">
                                                                        <strong>{person.firstName} {person.lastName}</strong><br />
                                                                        zm. {formatDate(person.deathDate)} r.
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <span style={{ fontSize: 18, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                        <span>Zdjęcia: {grave.images.length === 0 ?
                                                            <FontAwesomeIcon icon={faX} style={{ color: 'red' }} /> :
                                                            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />}
                                                        </span>
                                                        <span>Data opłacenia: {grave.dateOfPayment === null ?
                                                            <FontAwesomeIcon icon={faX} style={{ color: 'red' }} /> :
                                                            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />}
                                                        </span>
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }

                                <div style={{ display: 'flex', gap: 20, padding: '20px 0', flex: '100%' }}>
                                    {
                                        id > 1 ?
                                            <Link to={`/admin/cmentarz/groby/${parseInt(id) - 1}`} onClick={forceUpdate}>
                                                <button>
                                                    <FontAwesomeIcon icon={faChevronLeft} /> Strona {parseInt(id) - 1}
                                                </button>
                                            </Link>
                                            : null
                                    }
                                    {
                                        id >= 1 && id < maxSites ?
                                            <Link to={`/admin/cmentarz/groby/${parseInt(id) + 1}`} onClick={forceUpdate}>
                                                <button>
                                                    Strona {parseInt(id) + 1} <FontAwesomeIcon icon={faChevronRight} />
                                                </button>
                                            </Link>
                                            : null
                                    }

                                </div>
                            </>
                                : <p>Trwa ładowanie grobów ...</p>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default AllGraves